import React, { useContext } from "react"
import { PageContext, PageData } from "../PageLayout"
import styled from "styled-components/macro"
import { PageMargin } from "../GlobalStyles"
import getCldnyUrl from "../scripts/getCldnyUrl"

const LogoWrap = styled(PageMargin)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
`

const LogoStyles = styled.a`
  display: block;
  position: absolute;
  top: -10px;
  left: 0;
  padding: 20px 5px 5px;
  background-color: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.75);
  @media screen and (min-width: 1400px) {
    padding: 30px 20px 20px;
  }
  img {
    height: 50px;
    @media screen and (min-width: 1400px) {
      height: 100px;
    }
  }
`

const Logo = () => {
  const pageData: PageData = useContext(PageContext)
  const imgFilename = pageData.logo
  return (
    <LogoWrap>
      <LogoStyles href="/">
        <img src={getCldnyUrl(imgFilename, "h_100,q_auto,f_auto")} />
      </LogoStyles>
    </LogoWrap>
  )
}

export default Logo
