import React, { useContext } from "react"
import { PageContext, PageData } from "../PageLayout"
import { PageMargin } from "../GlobalStyles"
import { FaFacebook, FaYoutube, FaComments } from "react-icons/fa"
import styled from "styled-components/macro"
import "fontsource-open-sans/600.css"
import CovidButton from "./CovidButton"

const HeaderTopStyles = styled(PageMargin)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 15px;
  @media screen and (min-width: 1400px) {
    padding: 0;
  }
  a,
  svg {
    display: block;
  }
  div {
    margin-left: 25px;
  }
  a {
    margin-left: 10px;
    margin-bottom: 5px;
    @media screen and (min-width: 375px) {
      margin-left: 15px;
      margin-bottom: 0;
    }
    @media screen and (min-width: 550px) {
      margin-left: 25px;
    }
  }
`

const HeadeTopLinkWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 75px;
  padding: 0;
  @media screen and (min-width: 375px) {
    padding: 3px 0;
    width: 200px;
  }
  @media screen and (min-width: 550px) {
    width: 100%;
    padding: 15px 0;
  }
`

const PhoneNumber = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 1em;
  color: white;
  margin-bottom: 7px;
  width: 100%;
  text-align: right;
  display: none;
  @media screen and (min-width: 375px) {
    display: block;
  }
  @media screen and (min-width: 410px) {
    font-size: 20px;
  }
  @media screen and (min-width: 550px) {
    width: auto;
    margin-bottom: 0;
  }
  @media screen and (min-width: 1400px) {
    font-size: 30px;
  }
`

const IconLink = styled.a`
  display: block;
  svg {
    font-size: 20px;
    @media screen and (min-width: 1400px) {
      font-size: 30px;
    }
  }
`

const HeaderTop = () => {
  const pageData: PageData = useContext(PageContext)
  const {
    facilityPhone,
    facebookPageURL,
    youTubePlaylist,
    GoogleReviewURL,
  } = pageData
  return (
    <HeaderTopStyles>
      <HeadeTopLinkWrap>
        <PhoneNumber>{facilityPhone}</PhoneNumber>
        <CovidButton />
        <IconLink href={facebookPageURL}>
          <FaFacebook />
        </IconLink>
        <IconLink href={youTubePlaylist}>
          <FaYoutube />
        </IconLink>
        <IconLink href={GoogleReviewURL}>
          <FaComments />
        </IconLink>
      </HeadeTopLinkWrap>
    </HeaderTopStyles>
  )
}

export default HeaderTop
