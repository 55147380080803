import React, { useContext } from "react"
import styled from "styled-components/macro"
import { PageContext, PageData } from "../PageLayout"
import color from "../scripts/colors"

const FooterStyles = styled.footer`
  background-color: ${color("grey", "dk")};
  color: white;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  line-height: 1em;
  text-transform: uppercase;
  padding: 20px;
  margin: 75px 0 0;
`

const Footer = () => {
  const year = new Date().getFullYear()
  const pageData: PageData = useContext(PageContext)
  const { facilityName } = pageData
  return (
    <FooterStyles id="site-footer">
      &copy; {year} {facilityName}
    </FooterStyles>
  )
}

export default Footer
