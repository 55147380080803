import React, { useContext } from "react"
import { PageContext, PageData } from "../PageLayout"
import styled from "styled-components/macro"

const MainStyles = styled.main``

const Main = ({ children }) => {
  const pageData: PageData = useContext(PageContext)
  return <MainStyles>{children}</MainStyles>
}

export default Main
