type Color = { base: string; lt?: string; dk?: string; hlt?: string }

type Colors = { [key: string]: Color }

const colors: Colors = {
  main: {
    base: "rgb(116, 29, 19)",
    lt: "rgb(138, 71, 64)",
    dk: "rgb(116, 29, 19)",
  },
  link: {
    base: "rgb(232, 198, 29)",
    hlt: "rgb(255, 192, 18)",
  },
  grey: {
    base: "rgb(140, 135, 121)",
    lt: "rgb(189, 182, 162)",
    dk: "rgb(102, 98, 88)",
  },
  success: {
    base: "rgb(18, 204, 49)",
  },
  error: {
    base: "rgb(232, 31, 16)",
  },
}

const color = (color: string, variation?: string, opacity?: number) => {
  let calcedColor
  if (!variation) {
    if (!colors[color].base) {
      calcedColor = colors[color]
    }
    calcedColor = colors[color].base
  } else {
    calcedColor = colors[color][variation as keyof Color]
  }
  if (opacity) {
    calcedColor = calcedColor
      .replace("rgb", "rgba")
      .replace(")", `, ${opacity})`)
  }
  return calcedColor
}

export default color
