import React from "react"
import styled from "styled-components/macro"
import color from "../scripts/colors"
import HeaderBottom from "./HeaderBottom"
import HeaderTop from "./HeaderTop"
import Logo from "./Logo"

const HeaderStyles = styled.header`
  position: relative;
  z-index: 2000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${color("grey")};
  @media screen and (min-width: 1400px) {
    flex-direction: column;
  }
`

const Header = () => {
  return (
    <HeaderStyles>
      <Logo />
      <HeaderTop />
      <HeaderBottom />
    </HeaderStyles>
  )
}

export default Header
