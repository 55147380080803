import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { PageDataType } from "./types/PageData"
import Header from "./components/Header"
import Main from "./components/Main"
import Footer from "./components/Footer"
import GlobalStyles from "./GlobalStyles"
import { Helmet } from "react-helmet"
import getCldnyUrl from "./scripts/getCldnyUrl"

// So can import context and type with 1 import
export type PageData = PageDataType

export const PageContext = React.createContext({} as PageDataType)

const PageLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query PageQuery {
      sanityFacility(_id: { eq: "ad7fce6e-f05b-4035-9a24-849f92716a02" }) {
        facilityName
        facilityUrl
        logo
        facilityImage
        facilityPhone
        facilityEmail
        address {
          facilityAddress
          facilityCity
          facilityState
          facilityZip
        }
        seniorMessagesUrl
        careerUrl
        covidGoogleSheetUrl
        facebookPageURL
        youTubePlaylist
        GoogleReviewURL
        contactRep {
          contactRepImageName
          contactRepImagePosition
          contactRepImageImage
        }
        services {
          _key
          name
          description
          detailList {
            ... on SanityServiceContentBlock {
              _key
              title
              text
            }
          }
        }
        testimonials {
          _key
          name
          text
        }
        banner
        tagline
        subTagline
        introParagraph
        video
        _rawDescription
        _rawHometownMomentsText
        _rawAdmissionsText
      }
    }
  `)

  const pageData = data.sanityFacility

  const cloudinaryUrlBuilder = (
    imageFilename: string,
    transformations?: string
  ) => {
    const trans = transformations ? `${transformations}/` : ""
    return `https://res.cloudinary.com/agoraservices/${trans}${imageFilename}`
  }

  const facilityImage = getCldnyUrl(pageData.facilityImage)

  const jsonLd = {
    "@context": "http://www.schema.org",
    "@graph": [
      {
        "@type": "LocalBusiness",
        additionalType: "Nursing",
        name: pageData.facilityName,
        url: pageData.facilityUrl,
        logo: getCldnyUrl(pageData.logo),
        description: pageData.introParagraph,
        image: pageData.facilityImage ? facilityImage : null,
        address: {
          "@type": "PostalAddress",
          streetAddress: pageData.address.facilityAddress,
          addressLocality: pageData.address.facilityCity,
          addressRegion: pageData.address.facilityState,
          postalCode: pageData.address.facilityZip,
          addressCountry: "US",
        },
        sameAs: pageData.facebookPageURL,
        contactPoint: {
          "@type": "ContactPoint",
          contactType: "Admissions",
          telephone: pageData.facilityPhone,
        },
      },
      {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": pageData.seniorMessagesUrl,
              name: "Senior Messages",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `${pageData.facilityUrl}/hometownMoments`,
              name: "Hometown Moments",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `${pageData.facilityUrl}/about`,
              name: "About Us",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `${pageData.facilityUrl}/career`,
              name: "Career",
            },
          },
          {
            "@type": "ListItem",
            position: 5,
            item: {
              "@id": `${pageData.facilityUrl}/contact`,
              name: "Contact",
            },
          },
          {
            "@type": "ListItem",
            position: 6,
            item: {
              "@id": pageData.facebookPageURL,
              name: "Facebook Page",
            },
          },
          {
            "@type": "ListItem",
            position: 7,
            item: {
              "@id": pageData.youTubePlaylist,
              name: "YouTube",
            },
          },
          {
            "@type": "ListItem",
            position: 7,
            item: {
              "@id": pageData.GoogleReviewURL,
              name: "Google Review",
            },
          },
        ],
      },
    ],
  }

  const tCardType = pageData.facilityImage ? "summary_large_image" : "summary"

  return (
    <PageContext.Provider value={pageData}>
      <Helmet>
        <title>{pageData.facilityName}</title>
        <meta name="description" content={pageData.introParagraph} />
        <meta property="og:title" content={pageData.facilityName} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={pageData.introParagraph} />
        {pageData.facilityImage && (
          <meta property="og:image" content={facilityImage} />
        )}
        {pageData.video && (
          <meta property="og:video" content={pageData.video} />
        )}
        <meta property="og:url" content={pageData.facilityUrl} />
        <meta name="twitter:card" content={tCardType} />
        <meta name="twitter:title" content={pageData.facilityName} />
        <meta name="twitter:description" content={pageData.introParagraph} />
        {pageData.facilityImage && (
          <meta name="twitter:image" content={facilityImage} />
        )}
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <GlobalStyles />
      <div id="sticky-footer__body">
        <Header />
        <Main>{children}</Main>
      </div>
      <Footer />
    </PageContext.Provider>
  )
}

export default PageLayout
