export default (url: string, transformations?: string) => {
  if (url) {
    const CLOUDINARY_REGEX = /^.+\.cloudinary\.com\/(?:[^\/]+\/)(?:(image|video)\/)?(?:(upload|fetch)\/)?(?:(?:[^_/]+_[^,/]+,?)*\/)?(?:v(\d+|\w{1,2})\/)?([^\.^\s]+)(?:\.(.+))?$/
    const cloudinaryArr = url.match(CLOUDINARY_REGEX)
    const transformInsert = transformations ? `${transformations}/` : ""
    if (cloudinaryArr) {
      return `https://res.cloudinary.com/agoraservices/${transformInsert}${cloudinaryArr[4]}.${cloudinaryArr[5]}`
    }
    return null
  }
  return null
}
