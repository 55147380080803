import styled, { createGlobalStyle } from "styled-components/macro"
import "fontsource-open-sans/400.css"
import "fontsource-open-sans/600.css"
import color from "./scripts/colors"
import "fontsource-open-sans/300.css"

const GlobalStyles = createGlobalStyle`
/* STICKY FOOTER */
html, 
body, 
  #___gatsby {
    margin: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
    @supports (display: grid) {
      height: 100%;
    }
  }
  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  #sticky-footer__body {
    flex: 1 0 auto;
  }
  #site-footer {
    flex-shrink: 0;
  }

/* GLOBAL STYLES */
body, input, textarea {
  font-family: 'Open Sans', sans-serif;
  font-display: swap;
}

input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="reset"],
.input-checkbox {
-webkit-appearance: none;
border-radius: 0;
}

a {
  color: ${color("link")};
  transition: color 0.25s;
  text-decoration: none;
  &:hover {
    color: ${color("link", "hlt")}
  }
  &:focus, &:active {
    outline: none
  }
}

h2 {
  font-weight: 300;
  color: ${color("main", "lt")};
  font-size: 35px;
  margin: 35px 0 15px;
  &:first-child {
    margin-top: 0
  }
}

p {
  margin-top: 0;
  line-height: 1.7em
}

`

export default GlobalStyles

export const PageMargin = styled.div`
  width: 93%;
  margin: 0 auto;
  @media screen and (min-width: 1400px) {
    width: 1300px;
  }
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${color("link")};
  transition: background-color 0.25s;
  padding: 10px calc(14px - 0.2rem) 10px 14px;
  font-size: 17px;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  line-height: 1em;
  border: none;
  border-radius: 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.75);
  &:hover {
    background-color: ${color("link", "hlt")};
    color: white;
  }
  &:focus,
  &:active {
    outline: none;
  }
`

type SectionTitleProps = {
  left?: boolean
}

export const SectionTitle = styled.h2`
  display: block;
  color: ${color("main", "lt")};
  font-size: 50px;
  font-weight: 300;
  text-align: ${(props: SectionTitleProps) => (props.left ? "left" : "center")};
  margin: 0 auto 35px;
`

export const PageTitle = styled.h1`
  display: block;
  color: ${color("main", "lt")};
  font-size: 45px;
  font-weight: 300;
  text-align: left;
  margin: 50px auto;
  @media screen and (min-width: 550px) {
    font-size: 60px;
  }
`
